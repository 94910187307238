<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12">
				<h6 class="text-h6">results: "{{ search_term }}"</h6>
			</v-col>
		</v-row>

		<v-row v-if="results.friends.length > 0">
			<v-col cols="12">
				<p class="text-center text-lg-left">Connections</p>
			</v-col>
			<v-col cols="12" sm="6" md="4" lg="3" v-for="(friend, i) in results.friends">
				<r-memory-tile
					connection
					:to="{name: 'Shared', params: {friend_id: friend.id}}"
					:title="friend.name"
					:thumbnail="friend.avatar"
				></r-memory-tile>
			</v-col>
		</v-row>

		<v-row class="mt-15" v-if="results.friends.length < 1 && results.memories.length < 1">
			<v-col cols="12">
				<p class="text-center text-lg-left">no results :(</p>
			</v-col>

		</v-row>

		<v-row class="mt-15" v-if="results.memories.length > 0">
			<v-col cols="12">
				<p class="text-center text-lg-left">Memories</p>
			</v-col>
			<v-col cols="12" sm="6" md="4" lg="3" v-for="(memory, i) in results.memories">
				<r-memory-tile
					:thumbnail="memory.thumbnail"
					:title="memory.title"
					:to="{name: 'ShowMemory', params: {id: memory.id}}"
					:curated="memory.curated"
					:shared="memory.shared"
					:icon="memory.icon"
				></r-memory-tile>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>

export default {
	name: 'SearchResults',
	props: ['search_term'],
	data: () => ({
		results: {
			friends: [
				{
					id: null
				}
			],
			memories: [
				{
					id: null
				}
			]
		}
	}),
	mounted() {
		this.search()
	},
	watch: {
		search_term() {
			this.search()
		}
	},
	methods: {
		search() {
			if (this.search_term.length > 0) {
				this.$api.search.show(this.search_term)
					.then(response => this.results = response.data)
					.catch(error => console.log(error))
			}
		},
	}
}
</script>
